import { get } from 'svelte/store';
import { getStoreForFilter } from '../stores/search-pagination';
import axios from 'axios';
import type { SearchUser } from '@/interfaces/types';

interface PaginatedResponse<T> {
    data: T[];
    next_page_url: string | null;
    prev_page_url: string | null;
    current_page: number;
    total: number;
    per_page: number;
    hasMore: boolean;
}

interface SearchResponse<T> {
    data: PaginatedResponse<T>;
    success: boolean;
}

export async function loadMoreSearchResults(filter: string) {
    const store = getStoreForFilter(filter);
    const state = get(store);

    if (!state.nextPageUrl || !state.hasMore || state.loading) {
        return;
    }

    store.setLoading(true);

    try {
        const response = await axios.get<SearchResponse<any>>(state.nextPageUrl);

        if (response.status !== 200 || !response.data.success) {
            throw new Error('Failed to fetch search results');
        }

        const result = response.data;
        const hasMore = result.data.hasMore ?? result.data.next_page_url !== null;

        // Add type checking for users
        if (filter === 'people' && Array.isArray(result.data.data)) {
            store.append(
                result.data.data as SearchUser[],
                result.data.next_page_url,
                hasMore
            );
        } else {
            store.append(
                result.data.data,
                result.data.next_page_url,
                hasMore
            );
        }
    } catch (error) {
        const errorMessage = error instanceof Error ? error.message : 'Failed to load search results';
        store.setError(errorMessage);
        console.error('Error loading search results:', error);
    }
}

// Helper function to initialize search results
export function initializeSearchResults(
    filter: string,
    initialData: any[],
    paginationConfig: PaginatedResponse<any> | null
) {
    const store = getStoreForFilter(filter);

    if (paginationConfig) {
        const hasMore = paginationConfig.hasMore ?? paginationConfig.next_page_url !== null;
        store.initialize(
            initialData,
            paginationConfig.next_page_url,
            hasMore
        );
    } else {
        store.reset();
    }
}
